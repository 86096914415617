import React, { useState } from 'react';

//import the Photo component
import Photo from './Photo';

//import the Lightbox component
import Lightbox from '../components/Lightbox';

function PhotoList(props) {
  //state for whether/not to show our lightbox
  const [show, setShow] = useState(false);

  //state for title for lightbox
  const [title, setTitle] = useState('');

  //state for photo for lightbox
  const [photo, setPhoto] = useState('');
  //state for album for lightbox
  const [album, setAlbum] = useState('');

  //find the album the user is looking at
  var albumToDisplay = props.albums.albums.find(x => x.title === props.title)

  const showLightbox = (title, album, photo) => {
    setShow(true);
    setTitle(title);
    setPhoto(photo);
    setAlbum(album);
  };

  const closeLightbox = () => {
    setShow(false);
  }

  return (
    <div className="card-deck text-center mx-auto">
    {albumToDisplay ? (
      albumToDisplay.photos.map((item) => (
        <Photo album={props.title} name={item.name} title={item.title} key={props.title + "_" + item.name} show={showLightbox} />
      ))
    ) : (
      <h1>Loading...</h1>
    )
    }
    <Lightbox show={show} close={closeLightbox} title={title} album={album} name={photo} />
    </div>
  );
}

export default PhotoList;