import React from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

import './Photo.css';

function Photo(props) {
  const thumbSrc = "/photos/" + props.album + "/" + props.name + '_thumb.jpg';

  return (
    <Card key={props.title} className="mb-3 no-border" >
      <Button variant="outline-light" onClick={() => props.show(props.title, props.album, props.name)}>
      <Card.Img src={thumbSrc} />
      <Card.ImgOverlay className="d-flex align-items-end btn-link">
        <Card.Title>{props.title}</Card.Title>
      </Card.ImgOverlay>
      </Button>
    </Card>
  );
}

export default Photo;