import React from 'react';
import Modal from 'react-bootstrap/Modal';

//this component is stateless - state is kept in the parent

import './Lightbox.css';

function Lightbox(props) {
  //when the close button is clicked, use the close function that was passed from our parent to close the modal
  const handleClose = () => props.close();

  const mediumSrc = "/photos/" + props.album + "/" + props.name + '_medium.jpg';

  return (
    <Modal size="lg" show={props.show} onHide={handleClose}>
    <Modal.Header closeButton>
      <Modal.Title>{props.title}</Modal.Title>
    </Modal.Header>
    <Modal.Body><img src={mediumSrc} className="img-fluid mx-auto d-block" alt={props.title} /></Modal.Body>
  </Modal>
  );
}

export default Lightbox;