import React from "react"

//import the bootstrap css
import 'bootstrap/dist/css/bootstrap.min.css';

//import the Header component
import Header from '../../components/Header';

//import Bootstrap stuff we're using
import Container from 'react-bootstrap/Container';

import PhotoList from '../../components/PhotoList';

//read albumdata from json
import AlbumData from "../../../content/index.json"

function Album(props) {
  //render interface
  return (
    <Container >
      <Header title={props.params.name} />
      <PhotoList albums={AlbumData} title={props.params.name} />
    </Container>
  );
}

export default Album;